import { createStore } from "vuex";

export default createStore({
  state: {
    navigation: {
      openIcon: '#menu',
      closeIcon: '#cancel',
      mobileNavIsOpen: false,
      currentUrl: ''
    },

    header: {
      home: {
        text: ['Remanufacture of starter motors, alternators, supply of related automotive parts for all vehicles & earth moving machines.',
          'Full waranty on parts & repairs. Delivery throughout South Africa'
        ],
        image: 'starter-motor-diagram.png'
      },
      about: {
        text: ['We are a team of Automotive engineers based in Roodepoort, Gauteng, with over 10 years of experience in the industry. We engineer & supply high quality parts to the public, parts stores, and fleet workshops throughout South Africa, Namibia, and Zimbabwe.'
        ],
        image: 'starter-motor-diagram.png'
      },
      repairs: {
        text: ['Repairs & complete remanufacturing of starter motors ,Alternators and components for cars, bakkies, trucks, buses, earth moving machines.'
        ],
        image: 'repairs.png',
        id: 'repairs'
      },
      parts: {
        text: ['We manufacture and stock a wide range of starter motor and alternator related parts. We can also manufacture custom orders at any quantity you require'
        ],
        image: 'starter-motor-diagram.png'
      },
      contact: {
        text: ['Send us a message via our contact form. We generally respond within 24 hours Monday - Friday'
        ],
        image: 'contact-us.png',
        id: 'contact'
      },


    },

    services: [
      {
        icon: '#worker',
        title: 'Mining sector',
        description: 'We supply a number of mines',
        body: 'Repairing of starter motors & Alternators. Supply of new units & spare auto-motive engineering parts.'
      },

      {
        icon: '#diode',
        title: 'Parts supply',
        description: 'South Africa, Namibia, Zimbabwe',
        body: 'Armatures, bearings, oil seals, brushes & brush holders, bushes, diodes & rectifiers, starter & alternator brackets, bendix drives, field coils, pulleys & overrunning pulleys, reduction repair kits, rotors & stators, sliprings, solenoid switches & parts, voltage regulators. Starter motor & alternator remanufacture & repair.'
      },
      {
        icon: '#repair',
        title: 'Emergency repairs',
        description: 'Emergency repairs',
        body: 'Emergency repairs of starter motors & alternators. Auto-motive engineering part sales'
      },

    ],

    clients: [
      {
        name: 'Breno Parts',
        img: 'breno-parts.png',
        url: 'http://www.brenoparts-autoelectrical.co.za/',
        class: "breno-parts"
      },

      {
        name: 'chris auto electrical',
        img: 'caelex.png',
        url: 'https://caelex.co.za/',
        class: 'caelex'
      },

      {
        name: 'electro city',
        img: 'electro-city.png',
        url: 'https://electro-city.co.za/',
        class: 'electro-city'
      },

      {
        name: 'Trysome',
        img: 'trysome.png',
        url: 'https://www.trysome.co.za/',
        class: "trysome"
      },
    ],

    companyProfile:  {
      
    },

    footer: {
      logo: 'logo.png',
      synopsis: 'Remanufacture of starter motors, alternators, supply of related automotive parts for all vehicles & earth moving machines.',
      facebook: '',
      contactNumber: '010 023 1986',
      contactNumberLink: 'tel:+2710 023 1986',
      contactIcon: '#phone',
      address: ['SHOP 3, VAN WYK STREET', 'ROODEPOORT, GAUTENG'],
      mapLocation: 'https://maps.app.goo.gl/pLyWt7J8kMt7ZQGn6',
      locationIcon: '#location',
      social: [
        {
          name: 'facebook',
          icon: '#facebook',
          url: ''
      },
      ]
    },

    about: {
      img: 'testBench.png',
      img_info: 'starter motor diagram',
      title: 'testing',
      heading: 'We have thorough testing practices',
      body: 'After repairing your item, we put it through a rigorous quality testing phase. We also have automated test benches that provide a print out of the results of the tests'
    },

    products: {
      heading: 'Parts',
      productList: [
        {
          img: 'bearings.jpeg',
          description: 'starter motor field coils'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'bendix drives'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'rotors & stators'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'Armatures'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'bearings'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'oil seals'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'brushes & brush holders'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'Sliprings'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'bushes'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'diodes & rectifiers'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'overrunning pulleys'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'Pulleys'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'starter/alternator brackets'
        },
        
        {
          img: 'bearings.jpeg',
          description: ' voltage regulators'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'reduction & repair kits'
        },
        
        {
          img: 'bearings.jpeg',
          description: 'solenoid switches & repair parts'
        },
        
        
      ]
    },

    map: {
      map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.053069580354!2d27.855900615704797!3d-26.16240106863053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95a1c32f8214b1%3A0x8e043f4dede204fb!2s3%20Van%20Wyk%20St%2C%20Roodepoort%2C%201724!5e0!3m2!1sen!2sza!4v1632244699557!5m2!1sen!2sza" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy"',


    },
    contactForm: {
      url: 'https://bennbliss.co.za/mautic/form/submit?formId=1',
      

    },
    repairs: {
      image: 'starter-motor-diagram.png'
    }


  },
  mutations: {},
  actions: {},
  modules: {},
});
