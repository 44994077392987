<template>
  <footer class="footer">
    <div class="footer__main-content-container">
      <div class="footer__company-info">
        <a href="/" class="footer__company-logo">
          <svg
          class="footer__company-logo-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="331"
            height="49"
            viewBox="0 0 331 49"
          >
            <g transform="translate(-119 -16)">
              <path
                d="M51.718,20.854H48.381V19.186H45.045V17.934l-3.893-2.92h-2.78V5.839a2.506,2.506,0,0,0-2.5-2.5h-.834V2.5a2.506,2.506,0,0,0-2.5-2.5H9.176A2.5,2.5,0,0,0,6.827,1.668H0v5H6.673v3.49A2.5,2.5,0,0,0,5,12.512v5.839H0V31.7H5v.834a2.506,2.506,0,0,0,2.5,2.5H32.532a2.5,2.5,0,0,0,2.349-1.668h.988A2.5,2.5,0,0,0,38.218,31.7h2.935l3.892-2.92V27.527h3.337V25.859h3.337ZM33.366,2.5v.834H30.03V10.01H11.678V1.668H32.532A.835.835,0,0,1,33.366,2.5ZM9.176,1.668h.834V10.01H8.342V2.5A.835.835,0,0,1,9.176,1.668ZM1.668,5V3.337h5V5ZM5,20.02v2.132a3.3,3.3,0,0,0-1.668-.464,3.3,3.3,0,0,0-1.668.464V20.02Zm0,5a1.668,1.668,0,1,1-1.668-1.668A1.67,1.67,0,0,1,5,25.025Zm-3.337,5V27.9a3.3,3.3,0,0,0,1.668.464A3.3,3.3,0,0,0,5,27.9V30.03Zm31.7,2.5a.835.835,0,0,1-.834.834H11.678V14.181H10.01V33.366h-2.5a.835.835,0,0,1-.834-.834V12.512a.835.835,0,0,1,.834-.834H28.361V31.7H30.03V11.678h2.5a.835.835,0,0,1,.834.834ZM36.7,30.864a.835.835,0,0,1-.834.834h-.834V12.512a2.506,2.506,0,0,0-2.5-2.5H31.7V5h4.171a.835.835,0,0,1,.834.834Zm6.673-2.92L40.6,30.03H38.371V16.683H40.6l2.78,2.085Zm3.337-2.085H45.045v-5h1.668Zm3.337-1.668H48.381V22.522H50.05Zm0,0"
                transform="translate(119 22.983)"
                fill="#fff"
              />
              <text
                transform="translate(181 53)"
                fill="#fff"
                font-size="35"
                font-family="Poppins-Bold, Poppins"
                font-weight="700"
              >
                <tspan x="0" y="0">Bennbliss Auto</tspan>
              </text>
            </g>
          </svg>
        </a>
        <p class="footer__synopsis">
          {{ footer.synopsis }}
        </p>

        <div v-for="social in footer.social" :key="social.index" class="footer__social">
          <a :href="footer.contactNumberLink" class="footer__contact-link"> 
              <svg class="footer__social-icon">
            <use
              :xlink:href="
                require('@/assets/images/icons.svg') + `${social.icon}`
              "
            ></use>
          </svg>
          </a>
        </div>

      </div>

      <div class="footer__additional-pages">
        <h3 class="footer__additional-pages-title">Additional Pages</h3>
        <ul>
          <li class="footer__list-item">
            <a href="terms.html" class="footer__additional-links"
              >Terms of Service</a
            >
          </li>
          <li class="footer__list-item">
            <a href="privacy.html" class="footer__additional-links"
              >Privacy Policy</a
            >
          </li>
          <li class="footer__list-item">
            <a href="cancellation.html" class="footer__additional-links"
              >Cancellation Policy</a
            >
          </li>
        </ul>
      </div>

      <div class="footer__contact-info">
        <h3 class="footer__contact-info-title">Contact Info</h3>
        <div class="footer__contact-number">
          <svg class="footer__contact-icon">
            <use
              :xlink:href="
                require('@/assets/images/icons.svg') + `${footer.contactIcon}`
              "
            ></use>
          </svg>
          <a :href="footer.contactNumberLink" class="footer__contact-link"> {{ footer.contactNumber }}</a>
        </div>

        <div class="footer__location">
          <svg class="footer__contact-icon">
            <use
              :xlink:href="
                require('@/assets/images/icons.svg') + `${footer.locationIcon}`
              "
            ></use>
          </svg>
          <a :href="footer.mapLocation" class="footer__contact-link"> {{ footer.address[0] }}<br/> {{footer.address[1]}}</a>
        </div>
      </div>
    </div>

    

    <div class="footer__copyright">
                    <p class="footer__copyright-text">Copyright &copy;
                        2021&mdash;
                        Made with <span class="heart"></span> by <a href="https://premierweb.co.za/" target="_blank" class='premier-web' rel="nofollow noopener">Premier Web</a>
                    </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    footer: {
      type: Object,
      required: true,
    },
  },
  name: "Footer",
};
</script>

<style lang="scss" scoped>

.footer {
  background-color: #121212;
  // margin: 5rem 0 0 0;
  padding: 7rem 4rem 2rem 4rem;

  @media screen and (max-width: 1040px) {
    text-align: center;
  }
  @media screen and (max-width: 450px) {
    padding: 5rem 1rem 2rem 1rem;
  }

  &__main-content-container {
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;

    @media screen and (max-width: 1040px) {
    flex-direction: column;
     justify-content: center;
     align-items: center;
  }
  }

  &__heading {
    margin-bottom: 3rem;
  }

  &__company-info {
    display: flex;
    flex-direction: column;
    width: 30%;
    // margin-right: auto;
    align-self: flex-start;

    @media screen and (max-width: 1040px) {
     align-self: center;
     width: 60%;
     padding-bottom: .5rem;
     border-bottom: 1px solid grey;
    }
    @media screen and (max-width: 550px) {
     width: 100%;
    }
  }

  &__company-logo {
      margin-bottom: 1rem;;
  }

  &__company-logo-svg {
    @media screen and (max-width: 400px) {
     width: 80vw;
    }
  }

  &__synopsis, &__copyright-text {
    //   width: 40%;
    color: #b1b1b1;
    font-size: 1.75rem;
  }

  &__social-icon {
    width: 5rem;
    height: 5rem;
    margin-top: 2rem;
  }

  &__additional-pages, &__contact-info {
    @media screen and (max-width: 1040px) {
    width: 60%;
    margin-top: 4rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid grey;
    }
    @media screen and (max-width: 550px) {
     width: 100%;
    }
  
  }

  &__additional-pages-title, &__contact-info-title {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 1.75rem;    
  }

  &__list-item {
    list-style-type: none;
    margin-bottom: 1.5rem;
  }

  &__additional-links {
    text-decoration: none;
    font-size: 1.5rem;
    color: #61e0b9;
  }

  &__contact-info {
    //   margin-left: 8rem;
      
  }

  &__contact-link {
      color: white;
      text-decoration: none;
      font-size: 1.5rem;
    //   margin-bottom: 2rem;

  }

  &__contact-icon {
      width: 2.5rem;
      height: 2.5rem;
      fill: #61e0b9;
      margin-right: 1.5rem;

      @media screen and (max-width: 1040px) {
      margin-bottom: 1rem;
    }
      
  }

  &__contact-number, &__location {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1040px) {
      justify-content: center;
      flex-direction: column;
    }
  }

  &__location {
      
  }

  &__copyright {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
  }

  &__copyright-text {
    @media screen and (max-width: 1040px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    }
  }


}

@keyframes beatingHeart {
 0% {
     transform: rotate(45deg);
 }
 90% {
     transform: scale(1.3) rotate(45deg);
 }
 100% {
     transform: scale(1.6) rotate(45deg);
 }
}


.footer .heart {
   @media screen and (max-width: 1040px) {
    margin: 2rem;
    }
    margin: 0 1rem;
    display: inline-block;
    background-color: #ff0000;
    z-index: 1;
    width: 2rem;
    height: 2rem;
    position: relative;
    transform: rotate(45deg);
    animation: beatingHeart 1s ease-out infinite;
    transition: all 0.5s; }
    .footer .heart::after, .footer .heart::before {
      content: "";
      background-color: red;
      width: 2rem;
      height: 2rem;
      border-radius: 100px;
      position: absolute; }
    .footer .heart::before {
      top: -50%;
      left: 0%; }
    .footer .heart::after {
      top: 0%;
      left: -50%; }
  .footer .premier-web {
    color: #61e0b9;
    font-size: 3rem;
    font-weight: 600;
    text-decoration: none;
    @media screen and (max-width: 1040px) {
    margin: 1rem;
    }
     }
//   .footer .premier-web:hover {
//     color: #61e0b9; 
//     }
// .services {
//   margin: 5rem 0;
//   text-align: center;

//   &__heading {
//     margin-bottom: 5rem;
//   }

//   &__card-container {
//     display: flex;
//     align-items: flex-start;
//     justify-content: space-evenly;
//   }

//   &__card {
//     width: 30%;
//   }

//   &__card-text {
//     padding: 2rem;
//     background-color: #f8f8f8;
//   }

//   &__card-title {
//     font-size: 2rem;
//     font-weight: 800;
//     color: #4a9396;
//   }

//   &__card-description {
//     font-size: 2.5rem;
//     font-weight: 600;
//   }

//   &__card-body-text {
//     font-size: 1.8rem;
//     width: 73.86%;
//     margin: auto;
//   }
// }
</style>


