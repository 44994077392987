<template>
  <section 
  class="profileDownload"
  ref="profileDownload"
  :class="{ profileDownloadInverted: inverted }"
  >
    
    <div class="profileDownload__container">
        <div class="profileDownload__text-container">
            <h2 class="profileDownload__heading"
            :class="{ profileDownload__headingInverted: inverted }"
            >Company Profile</h2>
        <p class="profileDownload__text"
        :class="{ profileDownload__textInverted: inverted }">
            Download our Company Profile 
        </p>
        </div>
        
        <a href="#" class="profileDownload__download-button" 
        :class="{ profileDownload__downloadBtnInverted: inverted }">
            Download
        </a>
    </div>
  </section>
</template>

<script>

export default {
  data() {
    return {
      inverted: this.invertColors
    };
  },
  props: {
    companyProfile: {
      type: Object,
      required: true,
    },
    invertColors: {
      type: Boolean,
      required: true
    }
  },

  name: "CompanyProfileDownload",
};
</script>

<style lang="scss" scoped>
.profileDownload {
  margin: 5rem 0;
  padding: 5rem 2rem;

  &Inverted {
    background-color: #00865d !important;
  }

  &__heading {
      color: #00865d;
      font-size: 1.75rem;
      font-weight: 600;
      text-transform: uppercase;

      &Inverted {
        color: #fff;
      }

      @media screen and (max-width: 550px) {
      visibility: hidden;;
  }
  }

  &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      position: relative;
  }

  &__text {
    color: #000;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.2;

    &Inverted {
    color: #fff;
    }

    @media screen and (max-width: 1140px) {
    font-size: calc(2.5rem + 1vw);
    margin-right: 2rem;
    }
    @media screen and (max-width: 550px) {
    text-align: center;
    margin-bottom: 0.5rem;
    }
  }

  &__download-button {
      text-decoration: none;
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
      background-color: #00865d;
      padding: 2rem 3rem;
      margin-top: 1rem;

    &::after {
        content: url('../assets/images/download.svg');
    }

  }

  &__downloadBtnInverted {
        border: 1px solid #fff;
      }
}
</style>


