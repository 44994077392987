<template>
  <div>
    <header>
      <Header :headerData="headerData" />
    </header>

    <main class="main">
      <Services :services="services" />
      <ClientSlider :clients="clients" :invertColors="invertColors" />
      <CompanyProfileDownload :companyProfile="companyProfile" :invertColors="invertColors" />
    </main>
    <Footer :footer="footer" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Services from "@/components/Services.vue";
import ClientSlider from "@/components/ClientSlider.vue";
import CompanyProfileDownload from "@/components/CompanyProfileDownload.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      headerData: {
        paragraphs: this.$store.state.header.home.text,
        image: this.$store.state.header.home.image,
      },
      services: this.$store.state.services,

      clients: this.$store.state.clients,

      footer: this.$store.state.footer,

      companyProfile: this.$store.state.companyProfile,

      invertColors: false
    };
  },
  name: "Home",
  components: {
    Header,
    Services,
    ClientSlider,
    CompanyProfileDownload,
    Footer,
  },

};
</script>


<style lang="scss">
.clients {
 

  &__heading {
    color: #fff;
  }
}

// //**********************************************************************************
//***************** ClientSlider ***********************************************
// *********************************************************************************/
.v-hl-container {
  display: flex;
  justify-content: center !important;

  @media screen and (max-width: 1326px) {
      justify-content: flex-start !important;
    }
}

</style>