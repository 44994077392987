<template>
  <div class="navbar">
    <div class="navbar__logo-box" ref="logo">
      <a href="/" class="navbar__logo-link" aria-label="Bennbliss logo">
        <svg
          class="navbar__logo-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="331"
          height="49"
          viewBox="0 0 331 49"
        >
          <g transform="translate(-119 -16)">
            <path
              d="M51.718,20.854H48.381V19.186H45.045V17.934l-3.893-2.92h-2.78V5.839a2.506,2.506,0,0,0-2.5-2.5h-.834V2.5a2.506,2.506,0,0,0-2.5-2.5H9.176A2.5,2.5,0,0,0,6.827,1.668H0v5H6.673v3.49A2.5,2.5,0,0,0,5,12.512v5.839H0V31.7H5v.834a2.506,2.506,0,0,0,2.5,2.5H32.532a2.5,2.5,0,0,0,2.349-1.668h.988A2.5,2.5,0,0,0,38.218,31.7h2.935l3.892-2.92V27.527h3.337V25.859h3.337ZM33.366,2.5v.834H30.03V10.01H11.678V1.668H32.532A.835.835,0,0,1,33.366,2.5ZM9.176,1.668h.834V10.01H8.342V2.5A.835.835,0,0,1,9.176,1.668ZM1.668,5V3.337h5V5ZM5,20.02v2.132a3.3,3.3,0,0,0-1.668-.464,3.3,3.3,0,0,0-1.668.464V20.02Zm0,5a1.668,1.668,0,1,1-1.668-1.668A1.67,1.67,0,0,1,5,25.025Zm-3.337,5V27.9a3.3,3.3,0,0,0,1.668.464A3.3,3.3,0,0,0,5,27.9V30.03Zm31.7,2.5a.835.835,0,0,1-.834.834H11.678V14.181H10.01V33.366h-2.5a.835.835,0,0,1-.834-.834V12.512a.835.835,0,0,1,.834-.834H28.361V31.7H30.03V11.678h2.5a.835.835,0,0,1,.834.834ZM36.7,30.864a.835.835,0,0,1-.834.834h-.834V12.512a2.506,2.506,0,0,0-2.5-2.5H31.7V5h4.171a.835.835,0,0,1,.834.834Zm6.673-2.92L40.6,30.03H38.371V16.683H40.6l2.78,2.085Zm3.337-2.085H45.045v-5h1.668Zm3.337-1.668H48.381V22.522H50.05Zm0,0"
              transform="translate(119 22.983)"
              fill="#fff"
            />
            <text
              transform="translate(181 53)"
              fill="#fff"
              font-size="35"
              font-family="Poppins-Bold, Poppins"
              font-weight="700"
            >
              <tspan x="0" y="0">Bennbliss Auto</tspan>
            </text>
          </g>
        </svg>
      </a>
    </div>
    <nav class="navbar__navigation">

      <ul class="navbar__navigation-list" ref="menu">

        <li v-for="route in this.routes.slice(0,-1)" :key="route.index"
        class="navbar__navigation-list-item" ref="menuItem">
          <router-link to="/" custom v-slot="{ }">
          <a v-if="this.mobileNavIsOpen" @click="mobileMenuRoute(`${route.path}`)" class="mobile-menu-navigation-link">{{ route.name }}</a>
          <a
            v-else
            :href="`${route.path}`"
            :id="`${route.name}`"
            >
            {{ route.name }}
          </a>
          </router-link>
        </li>

      </ul>
    </nav>
    <div class="navbar__contact" ref="number">
      <div class="navbar__contact-button">
        <svg class="navbar__contact-icon">
          <use xlink:href="@/assets/images/icons.svg#phone-call"></use>
        </svg>
        <a href="tel:+27100231986" class="navbar__contact-link">0100231986</a>
      </div>
    </div>
    <svg class="navbar__openMenu-icon" ref="hamburger" aria-label="Open the menu">
      <use
        :xlink:href="
          require('@/assets/images/icons.svg') + `${navigation.openIcon}`
        "
      ></use>
    </svg>
    <svg class="navbar__closeMenu-icon" ref="close"  aria-label="Close the menu">
      <use
        :xlink:href="
          require('@/assets/images/icons.svg') + `${navigation.closeIcon}`
        "
      ></use>
    </svg>
  </div>

</template>

<script>
export default {
  data() {
    return {
      routes: this.$router.options.routes,
      mobileNavIsOpen: false
    }
  },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
  },
  name: "Navigation",
  methods: {

    mobileMenuRoute(route) {
        this.$store.state.navigation.mobileNavIsOpen = false;
        this.mobileNavIsOpen = this.$store.state.navigation.mobileNavIsOpen;
        this.$refs.close.classList.remove('show-closeMenu-icon');
        this.$refs.hamburger.classList.remove('hide');
        this.$refs. menu.classList.remove('hamburger-menu');
        this.$refs.logo.classList.remove('none')
        this.$refs.number.classList.remove('none')
        if (route === 'NotFound') {
          return;
        } else {
          this.$router.push(route)
        }
        
    },

  },

  mounted() {
    this.$refs.hamburger.addEventListener('click', () => {
    this.$store.state.navigation.mobileNavIsOpen = true;
    this.mobileNavIsOpen = this.$store.state.navigation.mobileNavIsOpen;
    this.$refs.menu.classList.add('hamburger-menu');
    this.$refs.hamburger.classList.add('hide');
    this.$refs.close.classList.add('show-closeMenu-icon');
    this.$refs.logo.classList.add('none')
    this.$refs.number.classList.add('none')
  })


    this.$refs.close.addEventListener('click', () => {
    this.$store.state.navigation.mobileNavIsOpen = false;
    this.mobileNavIsOpen = this.$store.state.navigation.mobileNavIsOpen;
    this.$refs.close.classList.remove('show-closeMenu-icon');
    this.$refs.hamburger.classList.remove('hide');
    this.$refs. menu.classList.remove('hamburger-menu');
    this.$refs.logo.classList.remove('none')
    this.$refs.number.classList.remove('none')
  });

  }



};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->


<!-- 
The router-link-active on the current page is no longer working
We need to transition the hover on the page links. Perhaps a nice animation that has to do with electricity?
What about the "contact button" perhaps we could do that animation form the flexbox course?
What about an animation for the hamburger menu?
-->
<style scoped lang="scss">
.none {
  display: none !important;
}

.show {
  display: flex;
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  
  z-index: 10;
  padding: 0 0.5rem;
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 1140px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: 560px) {
    height: 105px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  // .router-link-active {
  //   // background: #4a9396;
  //   // background: #5deaba;
  //   background-color: #00865d;
  // }

  &__logo-box {
    margin-right: auto;
    @media screen and (max-width: 1019px) {
      margin-right: 1rem;
    }
  }

  &__logo-svg {
    @media screen and (max-width: 1140px) {
      width: 290px;
    }
    @media screen and (max-width: 560px) {
    width: 60vw;
    }
  }

  &__navigation {
    @media screen and (max-width: 1019px) {
      order: 1;
    }
    @media screen and (max-width: 900px) {
      order: 1;
    }
  }

  &__navigation-list {
    @media screen and (max-width: 1019px) {
      order: 1;
      display: none;
    }
    @media screen and (max-width: 900px) {
      order: -10;
    }
  }

  &__navigation-list-item {
    margin-right: 0.5rem;

    a {
      padding: 1.4rem 1.5rem;

      &:hover {
        background: #00865d;
      }
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media screen and (max-width: 1019px) {
      margin-left: 1rem;
      margin-right: auto;
    }
    @media screen and (max-width: 560px) {
    position: absolute;
    top: 50px;
    left: 0;
    }
    @media screen and (max-width: 560px) {
    width: 60vw;
    }
  }

  &__contact-button {
    display: flex;
    align-items: center;
    background-color: #4a9396;
    border-color: #4a9396;
    background-color: #00865d;
    border-color: #00865d;
    padding: 0.5rem;

    @media screen and (max-width: 560px) {
    width: 290px;
    }

  }

  &__contact-icon {
    height: 2rem;
    width: 2rem;
    fill: #fff;
  }

  &__contact-link {
    font-size: 2rem;
    font-weight: 600;

    @media screen and (max-width: 560px) {
    font-size: calc(1.5rem + 1vw);
    }

  }

  &__closeMenu-icon,
  &__openMenu-icon {
    width: 4rem;
    height: 4rem;
    fill: #fff;
    position: absolute;
    display: none;
  }

  &__openMenu-icon {
    top: 14%;
    right: 10px;

    @media screen and (max-width: 1019px) {
    display: inline-block;
    z-index: 100;
    }
  }
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #fff;
  padding: 0.5rem;
  text-decoration: none;
}

.hamburger-menu {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 100vw !important;
  height: 105vh !important;
  top: 0 !important;
  left: 0 !important;
  opacity: 0.98 !important;
  background: rgb(5, 5, 5) !important;
}



.hide {
  z-index: -1100 !important;
  opacity: 0;
}

.show-closeMenu-icon {
  display: inline-block;
  position: absolute;
  top: 1.25%;
  right: 11px;
  z-index: 1100 !important;

  @media screen and (max-width: 560px) {
    top: 15.25%;
    }
}

.mobile-menu-navigation-link {
  display: block !important;
  margin-top: 8vh;
  text-align: center;
}

</style>

