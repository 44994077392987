<template>
<div class="not-found">
    <h1>Oops!</h1>
    <h3>The page you're looking for could not be found</h3>
</div>
<a href="/" class="back-btn">Go back to our home page</a>
</template>

<style lang="scss">

#app {
    text-align: center;
}
.not-found {
    text-align: center;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40vh;
    margin-bottom: 3rem;
}

.back-btn { 
    border-radius: 5px;
    color: #fff;
    padding: 1rem;
    text-decoration: none;
    font-size: 2.2rem;
    background-color: #00865d;
    font-weight: 800;
    margin-top: 1rem;
    transition: all .25s ease-in;

    &:hover {
    background-color: white;
    border: 1px solid #00865d;
    color: inherit;
}

    &:active {
        background-color: #00865d;
        color: #fff;
    }
    

    @media screen and (max-width: 593px) {
      font-size: 6.5vw;
    }
}

</style>