<template>
  <section class="services">
    <h2 class="services__heading">Services</h2>

    <div class="services__card-container">
      <div
        class="services__card"
        v-for="service in services"
        :key="service.index"
      >
        <div class="services__card-icon">
          <svg class="services__contact-icon">
            <use
              :xlink:href="
                require('@/assets/images/icons.svg') + `${service.icon}`
              "
            ></use>
          </svg>
        </div>
        <div class="services__card-text">
          <h2 class="services__card-title">{{ service.title }}</h2>
          <h3 class="services__card-description">
            {{ service.description }}
          </h3>
          <p class="services__card-body-text">
            {{ service.body }}
          </p>
        </div>
      </div>

      
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      // paragraphs: this.$store.state.header.homeParagraph,
    };
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  name: "Services",
};
</script>

<style lang="scss" scoped>
// .section-heading {
//       @media screen and (max-width: 1041px) {
//       font-size: 3.8rem;
//     }
//     @media screen and (max-width: 650px) {
//       font-size: calc(2.6rem + 1vw)
//     }
// }
.services {
  margin: 5rem 0;
  text-align: center;

  &__heading {
    margin-bottom: 5rem;
    @media screen and (max-width: 1041px) {
      font-size: 3.8rem;
    }
    @media screen and (max-width: 650px) {
      font-size: calc(2.6rem + 1vw)
    }
  }

  &__card-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    @media screen and (max-width: 1041px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__card {
    width: 30%;
    @media screen and (max-width: 1041px) {
      width: 80%;
      margin-bottom: 5rem;
    }
    @media screen and (max-width: 495px) {
      width: 97.5%;
      
    }
  }

  &__contact-icon {
    width: 150px;
    height: 150px;
    @media screen and (max-width: 1041px) {
      width: 120px;
      height: 120px;
      margin-bottom: .5rem;
    }
    @media screen and (max-width: 650px) {
      width: 100px;
      height: 100px;
    }
  }

  &__card-text {
    padding: 2rem;
    background-color: #f8f8f8;

    @media screen and (max-width: 495px) {
      padding: 2rem 1.5rem;
      
    }
  }

  &__card-title {
    font-size: 2rem;
    font-weight: 800;
    color: #00865d;
  }

  &__card-description {
    font-size: 2.5rem;
    font-weight: 600;
    @media screen and (max-width: 650px) {
      font-size: calc(1.6rem + 1vw);
    }
  }

  &__card-body-text {
    font-size: 1.8rem;
    width: 100%;
    margin: auto;
  }
}
</style>


