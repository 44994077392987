<template>
  <section class="clients"
  :class="{clients__Inverted: inverted}"
  >
    <h2 class="clients__heading section-heading" 
    :class="{clients__heading__Inverted: inverted}"
    >Some of our Clients</h2>
    <div class="clients__slider-container">
      <vue-horizontal responsive>
        <div v-for="client in clients" :key="client.index" 
        class="slider-item" 
        :class="{sliderInverted: inverted}"
        >
          <a :href="client.url" class="clients__website-link">
            <img
            class="clients__image"
              :class="`${client.class}`"
              v-bind:src="require('@/assets/images/' + client.img)"
              :alt="`${client.class} + logo`"
            />
          </a>
        </div>
      </vue-horizontal>
    </div>
  </section>
</template>

<script>
import VueHorizontal from "vue-horizontal";
export default {
  components: { VueHorizontal },
  data() {
    return {
      inverted: this.invertColors
    };
  },
  props: {
    clients: {
      type: Array,
      required: true,
    },
    invertColors: {
      type: Boolean,
      required: true
    }
    
    // centerUtilityClass
  },
  name: "ClientSlider",
};
</script>

<style lang="scss" scoped>
.slider-item {
  padding: 16px 44px;
  margin: 1rem;
  background: #fff;

  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.sliderInverted {
  border: 2px solid #00865d;
}

.clients {
  text-align: center;
  background-color: #00865d;
  margin: 5rem 0;

  &__Inverted {
    background-color: #fff;
  }

  &__heading {
    margin-bottom: 3rem;

    &__Inverted {
      color: #000;
    }
  }
  padding: 5rem 5rem;
  padding: 5rem 2.5rem;

  // &__image {
  //   width: 200px;
  //   height: 70px;
  // }

  // &__slider-container {
  //   display: flex;
  //   justify-content: center;
  // }
}
// .clients__image {
//   width: 200px;
//   height: auto;
//   // background-color: red;
// }

// //**********************************************************************************
//***************** UTILITY CLASSES ***********************************************
// *********************************************************************************/
.trysome {
  margin-top: 2.5rem;
}
.breno-parts {
  margin-top: 1.1rem;
}




// .services {
//   margin: 5rem 0;
//   text-align: center;

//   &__heading {
//     margin-bottom: 5rem;
//   }

//   &__card-container {
//     display: flex;
//     align-items: flex-start;
//     justify-content: space-evenly;
//   }

//   &__card {
//     width: 30%;
//   }

//   &__card-text {
//     padding: 2rem;
//     background-color: #f8f8f8;
//   }

//   &__card-title {
//     font-size: 2rem;
//     font-weight: 800;
//     color: #4a9396;
//   }

//   &__card-description {
//     font-size: 2.5rem;
//     font-weight: 600;
//   }

//   &__card-body-text {
//     font-size: 1.8rem;
//     width: 73.86%;
//     margin: auto;
//   }
// }
</style>


