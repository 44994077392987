<template>
  <header class="header" :id='headerData.id'>
    <div class="header__text">
      <!-- <p class="header__paragraph" v-for="paragraph in this.$store.state.header.homeParagraph" :key="paragraph.index">
        {{ paragraph }}
      </p> -->
      <p
        class="header__paragraph"
        v-for="paragraph in headerData.paragraphs"
        :key="paragraph.index"
      >
        {{ paragraph }}
      </p>
      <!-- <p class="header__paragraph">
        Full waranty on parts & repairs.
Delivery throughout South Africa
      </p> -->
    </div>
    <div class="header__image-container">
      <img
        v-bind:src="require('../assets/images/' + headerData.image)"
        alt="starter motor"
        class="header__image"
      />
    </div>
  </header>
</template>

<script>
///Users/dehsen/Documents/Portfolio/bennbliss-auto/bennbliss-auto/bennbliss-auto/src/assets/images/starter-motor-diagram.png
// -paragraph content will be stored in the vue store
// -image will also be stored in vue store
// -when a person visits a certain page - load the data from the vue store for the header component
// WE CAN STORE THE DATA OF EACH COMPONENT IN ITS VIEW AS DATA. THE PARENT WILL HAVE TO PASS ITS DATA TO THE COMPONENT TO RENDER

export default {
  data() {
    return {
      // paragraphs: this.$store.state.header.homeParagraph,
    };
  },
  props: {
    headerData: {
      type: Object,
      required: true,
    },
  },
  name: "Header",
};
</script>


