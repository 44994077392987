<template>
  <div id="app">
    <Navigation :navigation="navigation" />

    <router-view v-slot="{ Component }">
      <transition name="moveUp">
        <component :is="Component" :key="$route.path"></component>
      </transition>
    </router-view>
  </div>

</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
  data() {
    return {
      navigation: this.$store.state.navigation,
      currentUrl: ''
    };
  },

  name: "App",
  components: {
    Navigation,
  },


  watch: {
    updateCurrentUrl() {
      this.currentUrl = this.$store.state.navigation.currentUrl;
    }
  },

  mounted() {

      const homepage = document.getElementById('Home');
      if (window.location.pathname === '/') {
        homepage.classList.add('active');
      } else {
        // get current URL from browser
        let currentUrl = window.location.pathname.slice(1);
        // add current URL with the first letter UpperCased to the Vuex store, and assign it to the currentUrl variable
        currentUrl = this.$store.state.navigation.currentUrl = currentUrl.substring(0,1).toUpperCase() + currentUrl.slice(1);
        // Select the id of the currentPage (ID had been bound from the Vuex store, onto each page navigation link of the Navigation component)
        const activePage = document.querySelector('#' + currentUrl);
        // Add active class
        activePage.classList.add('active');
      }
      
  }, 
};
</script>




<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;800&display=swap");

//Base styles
:root {
  --color-grey: rgba(0, 0, 0, 0.5);
  --color-green: #00865d;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.4;
  color: #2c3e50;
}

h2 {
  font-size: 4.2rem;
  color: #000;
}

//**********************************************************************************
//***************** NAVIGATION COMPONENT *******************************************
// *********************************************************************************/
.navbar {
  position: fixed;
  width: 100%;
}

.active {
  background-color: #00865d;
  padding: 1.4rem;
}

//**********************************************************************************
//***************** HEADER COMPONENT ***********************************************
// *********************************************************************************/

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 5rem 5rem 5rem;
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  
  @media screen and (max-width: 868px) {
      flex-direction: column-reverse;
      padding-left: 1rem;
      padding-right: 1rem;
    }

  @media screen and (max-width: 560px) {
    padding-top: 12rem;
    }
  
  &__text {
    max-width: 45%;
    @media screen and (min-width: 1042px) and (max-width: 1400px) {
      max-width: 50%;
      font-size: 2.75rem;
    }
    @media screen and (max-width: 1041px) {
      max-width: 50%;
      font-size: 2.25rem;
    }
    @media screen and (max-width: 868px) {
      max-width: 80%;
      font-size: calc(1.6rem + 1vw);
    }
    @media screen and (max-width: 576px) {
      max-width: 95.5%;
      font-size: calc(1.4rem + 1vw);
    }
  }

  &__image {
    width: 800px;
    height: 450px;
    width: 100%;
    height: auto;
    
    @media screen and (max-width: 868px) {
      width: 65vw;
    }
    @media screen and (max-width: 393px) {
      width: 70vw;
    }
    
    
  }

  &__paragraph:not(:last-child) {
    margin-bottom: 4rem;
  }
}

//**********************************************************************************
//***************** HEADER COMPONENT ***********************************************
// *********************************************************************************/

.moveUp-enter-active {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.moveUp-enter-active {
  animation: fadeIn 0.5s ease-in;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.moveUp-leave-active {
  animation: fadeOut 0.3s ease-in;
}

//**********************************************************************************
//***************** GLOBAL STYLING ***********************************************
// *********************************************************************************/
.section-heading {
      @media screen and (max-width: 1041px) {
      font-size: 3.8rem !important;
    }
    @media screen and (max-width: 650px) {
      font-size: calc(2.6rem + 1vw) !important;
    }
}

// //**********************************************************************************
//***************** UTILITY CLASSES ***********************************************
// *********************************************************************************/

</style>
